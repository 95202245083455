import { Controller } from '@hotwired/stimulus'

export default class WCTabScrollController extends Controller {
  static values = { tab: String }

  // MARK: - Actions

  scroll(event) {
    event.preventDefault()

    if (!this.hasTabValue) return

    const tabElement = document.querySelector('#tab-' + this.tabValue)
    if (tabElement == null) return

    const tabTitleElement = document.querySelector('#tab-title-' + this.tabValue + ' a')
    if (tabElement == null) return

    tabTitleElement.click()

    setTimeout(() => {
      const targetOffset = tabElement.getBoundingClientRect().top
      const scrollOffset = targetOffset + window.pageYOffset - 50
      window.scrollTo({ top: scrollOffset, behavior: 'smooth' })
    }, 50)
  }
}
