//
// ...
//

document.addEventListener('click', (event) => {
  if (event.target instanceof Element == null) return

  const proxyElement = event.target.closest('[data-back-click]')
  if (proxyElement == null) return

  const referrerList = proxyElement.getAttribute('data-back-click')
  if (typeof referrerList !== 'string' || referrerList.length < 1) return

  const referrers = referrerList.split(/\s+/).map((href) => {
    const anchorElement = document.createElement('a')
    anchorElement.href = href
    return anchorElement.href.replace(/\/$/, '')
  })

  const referrer = document.referrer.replace(/\/$/, '')
  console.log(referrer, referrers)
  if (referrers.includes(referrer) !== true) return

  event.preventDefault()
  event.stopPropagation()
  window.history.back()
})
