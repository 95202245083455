import { Controller } from '@hotwired/stimulus'

export default class HiddenSectionController extends Controller {
  static targets = ['section']

  // MARK: - Properties

  get isOpen() {
    return this.element.classList.contains('hidden-section--open')
  }

  // MARK: - Lifecycle

  connect() {
    if (document.readyState === 'complete') return

    const id = this.sectionTarget.id
    if (typeof id !== 'string' || id.length < 1 || window.location.hash !== `#${id}`) return

    this.element.classList.add('hidden-section--open')

    window.addEventListener(
      'load',
      () => {
        setTimeout(() => {
          const targetOffset = this.sectionTarget.getBoundingClientRect().top
          const scrollOffset = targetOffset + window.pageYOffset - 80
          window.scrollTo({ top: scrollOffset, behavior: 'smooth' })
        }, 250)
      },
      { once: true }
    )
  }

  // MARK: - Actions

  toggle() {
    if (this.isOpen) {
      this.close()
    } else {
      this.open()
    }
  }

  open() {
    if (this.isOpen) return

    const { width, height } = this.computeSize(this.sectionTarget)
    this.sectionTarget.style.setProperty('width', width.toString())
    this.sectionTarget.style.setProperty('height', '0')

    // Force a reflow of the section, as we need the animation to start from the inline styles.
    void this.sectionTarget.offsetHeight

    this.sectionTarget.style.setProperty('height', height.toString())
    this.element.classList.add('hidden-section--open')
    this.prepareForCleanup()
  }

  close() {
    if (this.isOpen == false) return

    const { width, height } = this.computeSize(this.sectionTarget)
    this.sectionTarget.style.setProperty('width', width.toString())
    this.sectionTarget.style.setProperty('height', height.toString())

    // Force a reflow of the section, as we need the animation to start from the inline styles.
    void this.sectionTarget.offsetHeight

    this.sectionTarget.style.setProperty('height', '0')
    this.element.classList.remove('hidden-section--open')
    this.prepareForCleanup()
  }

  // MARK: - Transitions

  cleanup() {
    this.sectionTarget.style.removeProperty('width')
    this.sectionTarget.style.removeProperty('height')
  }

  prepareForCleanup() {
    const handler = (event) => {
      if (event.target != this.sectionTarget) return

      this.sectionTarget.removeEventListener('transitionend', handler)
      this.cleanup()
    }

    this.sectionTarget.addEventListener('transitionend', handler)
  }

  // MARK: - Helpers

  computeSize(element) {
    const { width } = getComputedStyle(element)
    element.style.setProperty('width', width)
    element.style.setProperty('height', 'auto')
    element.style.setProperty('position', 'absolute')
    element.style.setProperty('visibility', 'hidden')

    const { height } = getComputedStyle(element)
    element.style.removeProperty('width')
    element.style.removeProperty('height')
    element.style.removeProperty('position')
    element.style.removeProperty('visibility')

    return { width, height }
  }
}
