import { Controller } from '@hotwired/stimulus'

export default class KlarnaProductPlacementController extends Controller {
  static values = { selector: String }

  // MARK: - Lifecycle

  connect() {
    if (this.handler) return

    document.addEventListener('update_checkout', (e) => console.log(e))

    this.handler = (event) => {
      if (event.target instanceof Element === false) return

      const element = event.target.closest(this.selectorValue)
      if (element) setTimeout(() => this.updatePurchaseAmount(element), 0)
    }

    document.addEventListener('change', this.handler)
    document.addEventListener('click', this.handler)
    document.addEventListener('input', this.handler)
  }

  disconnect() {
    if (this.handler == null) return

    document.removeEventListener('change', this.handler)
    document.removeEventListener('click', this.handler)
    document.removeEventListener('input', this.handler)
    delete this.handler
  }

  // MARK: - Actions

  updatePurchaseAmount(element) {
    const price = this.findPrice(element)
    if (price == null) return

    const quantity = this.findQuantity(element)
    if (quantity == null) return

    const currentPurchaseAmount = this.element.getAttribute('data-purchase-amount')
    const updatedPurchaseAmount = (price * quantity).toString()
    if (updatedPurchaseAmount === currentPurchaseAmount) return

    this.element.setAttribute('data-purchase-amount', updatedPurchaseAmount)
    if (window.Klarna && window.Klarna.OnsiteMessaging) window.Klarna.OnsiteMessaging.refresh()
  }

  // MARK: - Price Helpers

  findPrice(element) {
    const priceNode = this.findPriceTextNode(element)
    if (priceNode == null) return null

    const price = parseFloat(priceNode.wholeText)
    if (isNaN(price)) return null

    return Math.floor(price * 100)
  }

  findPriceTextNode(element) {
    const priceElement = this.findPriceElement(element)
    if (priceElement == null) return null

    for (const node of priceElement.childNodes) {
      if (node instanceof Text) return node
    }

    return null
  }

  findPriceElement(element) {
    return (
      element.querySelector('.woocommerce-variation .woocommerce-variation-price .price .amount > bdi') ||
      element.querySelector('.price .amount > bdi')
    )
  }

  // MARK: - Quantity Helpers

  findQuantity(element) {
    const quantityElement = this.findQuantityElement(element)
    if (quantityElement == null) return null

    const quantity = parseInt(quantityElement.value)
    if (isNaN(quantity)) return null

    return quantity
  }

  findQuantityElement(element) {
    return element.querySelector('input.qty[name="quantity"]')
  }
}
