import { Controller } from '@hotwired/stimulus'
import Splide from '@splidejs/splide'

export default class ReviewsController extends Controller {
  // MARK: - Lifecycle

  connect() {
    this.splide = new Splide(this.element, {
      arrows: false,
      autoplay: false,
      breakpoints: {
        576: {
          perPage: 2
        },
        992: {
          perPage: 3
        },
        1200: {
          perPage: 4
        }
      },
      clones: 0,
      drag: false,
      focus: 0,
      gap: 16,
      interval: 10000,
      mediaQuery: 'min',
      pauseOnHover: true,
      perPage: 1,
      type: 'loop'
    })

    this.splide.mount()
    this.splide.on('resized', () => this.splideDidResize())
    this.splideDidResize()
  }

  disconnect() {
    this.splide.destroy(true)
    delete this.splide
  }

  // MARK: - Splide Events

  splideDidResize() {
    const slidesLength = this.splide.length
    const slidesPerPage = this.splide.options.perPage
    const currentClones = this.splide.options.clones
    const requiredClones = slidesLength > slidesPerPage ? slidesPerPage : 0
    if (requiredClones === currentClones) return

    const overflow = requiredClones > 0
    this.splide.options = { clones: requiredClones, drag: overflow }

    if (overflow) {
      this.splide.Components.Autoplay.play()
    } else {
      this.splide.Components.Autoplay.pause()
      this.splide.go(0)
    }
  }
}
