import { Controller } from '@hotwired/stimulus'

export default class HeaderSearchController extends Controller {
  // MARK: - Properties

  get isShown() {
    return this.element.classList.contains('nouvatan-header-search--show')
  }

  // MARK: - Actions

  toggle() {
    if (this.isShown) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    if (this.isShown) return

    this.element.classList.add('nouvatan-header-search--show')
  }

  hide() {
    if (this.isShown == false) return

    this.element.classList.remove('nouvatan-header-search--show')
  }
}
