//
// Proxies click events from one element to another.
//

document.addEventListener('click', (event) => {
  if (event.target instanceof Element == null) return

  const proxyElement = event.target.closest('[data-proxy-click]')
  if (proxyElement == null) return

  const selector = proxyElement.getAttribute('data-proxy-click')
  if (typeof selector !== 'string' || selector.length < 1) return

  event.preventDefault()
  event.stopPropagation()

  const targetElement = document.querySelector(selector)
  if (targetElement) targetElement.click()
})
