import { Controller } from '@hotwired/stimulus'
import { Loader } from '@googlemaps/js-api-loader'
import { MarkerClusterer } from '@googlemaps/markerclusterer'

export default class TrainersMapController extends Controller {
  static targets = ['annotations', 'map']

  // MARK: - Properties

  get annotations() {
    if (this.hasAnnotationsTarget !== true) return []

    try {
      return JSON.parse(this.annotationsTarget.innerText)
    } catch (error) {
      console.error(error)
      return []
    }
  }

  // MARK: - Lifecycle

  initialize() {
    this.loader = new Loader({
      apiKey: 'AIzaSyDlXUVOUlEQ0lchcsnS3ImCfZPavoS9Ce8',
      libraries: ['places'],
      version: 'weekly'
    })

    this.loader.load().then(() => {
      this.isGoogleMapsLoaded = true
      if (this.isSetupDeferred !== true) return

      this.isSetupDeferred = false
      this.setupMap()
    })
  }

  connect() {
    if (this.isGoogleMapsLoaded) {
      this.setupMap()
    } else {
      this.isSetupDeferred = true
    }
  }

  disconnect() {
    this.isSetupDeferred = false
    this.tearDownMap()
  }

  // MARK: - Map

  setupMap() {
    if (this.hasMapTarget !== true) return

    this.tearDownMap()

    this.map = new google.maps.Map(this.mapTarget, {
      center: { lat: 54.5, lng: -4.0 },
      zoom: 6,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false
    })

    this.markers = this.annotations.map((annotation) => this.addMarker(annotation))
    this.markerClusterer = new MarkerClusterer({ map: this.map, markers: this.markers })

    this.fitToBounds()
    this.setupSearchBox()
  }

  tearDownMap() {
    if (this.map) {
      google.maps.event.clearInstanceListeners(this.map)
      delete this.map
    }

    delete this.markers
    delete this.markerClusterer
  }

  fitToBounds() {
    const bounds = new google.maps.LatLngBounds()
    this.markers.forEach((marker) => {
      const position = marker.getPosition()
      if (position) bounds.extend(position)
    })

    this.map.fitBounds(bounds)
  }

  // MARK: - Markers

  addMarker(annotation) {
    const position = new google.maps.LatLng({
      lat: parseFloat(annotation.latitude),
      lng: parseFloat(annotation.longitude)
    })

    const marker = new google.maps.Marker({
      map: this.map,
      draggable: false,
      position: position
    })

    marker.addListener('click', () => {
      this.showMarkerPopup(annotation, marker)
    })

    return marker
  }

  showMarkerPopup(annotation, marker) {
    const containerElement = document.createElement('div')
    containerElement.style.display = 'flex'
    containerElement.style.alignItems = 'center'
    containerElement.style.justifyContent = 'center'

    if (annotation.image_url) {
      const imageElement = document.createElement('img')
      imageElement.src = annotation.image_url
      imageElement.style.float = 'left'
      imageElement.style.width = '64px'
      imageElement.style.height = '64px'
      imageElement.style.marginRight = '8px'
      imageElement.style.padding = '2px'
      imageElement.style.border = '1px solid #9b8a59'
      imageElement.style.borderRadius = '50%'
      containerElement.appendChild(imageElement)
    }

    const infoElement = document.createElement('div')
    infoElement.style.display = 'flex'
    infoElement.style.flexDirection = 'column'
    infoElement.style.alignItems = 'flex-start'
    infoElement.style.fontFamily = 'Montserrat, sans-serif'
    containerElement.appendChild(infoElement)

    const addAttribute = (label, text) => {
      const labelElement = document.createElement('strong')
      labelElement.innerText = label
      labelElement.style.fontSize = '10px'
      labelElement.style.fontWeight = '600'
      labelElement.style.lineHeight = '1'
      labelElement.style.textTransform = 'uppercase'
      infoElement.appendChild(labelElement)

      const textElement = document.createElement('strong')
      textElement.innerText = text
      textElement.style.marginBottom = '4px'
      textElement.style.fontSize = '16px'
      textElement.style.fontWeight = '400'
      textElement.style.lineHeight = '1.5'
      infoElement.appendChild(textElement)
    }

    addAttribute('Trainer', annotation.title)
    addAttribute('Region', annotation.region)

    if (annotation.url) {
      const anchorElement = document.createElement('a')
      anchorElement.innerText = `Train with ${annotation.first_name || 'them'}`
      anchorElement.href = annotation.url
      anchorElement.style.display = 'block'
      anchorElement.style.padding = '0.125rem 0.5rem'
      anchorElement.style.fontSize = '12px'
      anchorElement.style.fontWeight = '500'
      anchorElement.style.textDecoration = 'none'
      anchorElement.style.color = '#ffffff'
      anchorElement.style.backgroundColor = '#9b8a59'
      anchorElement.style.borderRadius = '4px'
      infoElement.appendChild(anchorElement)
    }

    if (this.lastInfoWindow) this.lastInfoWindow.close()
    this.lastInfoWindow = new google.maps.InfoWindow({ content: containerElement })
    this.lastInfoWindow.open(this.map, marker)
  }

  // MARK: - Search

  setupSearchBox() {
    const controls = this.map.controls[google.maps.ControlPosition.TOP_CENTER]
    if (!controls) return

    const containerElement = document.createElement('div')
    containerElement.classList.add('nouvatan-trainers-map__search')
    controls.push(containerElement)

    const linkElement = document.createElement('a')
    linkElement.href = '/trainers'
    linkElement.innerText = 'Grid\nView'
    containerElement.appendChild(linkElement)

    const inputElement = document.createElement('input')
    inputElement.type = 'text'
    inputElement.placeholder = 'Search for a place'
    containerElement.appendChild(inputElement)

    const searchBox = new google.maps.places.SearchBox(inputElement)
    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces()
      if (!places) return

      const bounds = new google.maps.LatLngBounds()
      places.forEach((place) => {
        if (place.geometry?.viewport) {
          bounds.union(place.geometry.viewport)
        } else if (place.geometry?.location) {
          bounds.extend(place.geometry.location)
        }
      })

      this.map.fitBounds(bounds)
    })

    this.map.addListener('bounds_changed', () => {
      searchBox.setBounds(this.map.getBounds())
    })
  }
}
